#root {
    height: 100%;
}

button,
input {
    outline: none !important;
    box-shadow: none !important;
}

input {
    &.form-control {
        border-color: #c7ccdb;
        font-weight: 300;
        font-size: 15px;
        padding: 10px 15px;
        border-radius: 6px;
    }
}

.btn {
    padding: 8px 55px;
    font-size: 15px;
    font-weight: bold;
}

.btn-warning {
    color: white !important;

    &:hover {
        background: #EA523E !important;
    }
}

textarea.form-control {
    outline: none !important;
    box-shadow: none !important;
}

/// <-- Bootstrap Overrides -->

.header-wrap {
    &.light-header {
        .navbar-collapse {
            background: transparent;
        }

        .nav-link,
        a:not(.dropdown-item) {
            color: white;
            text-decoration: none;

            &.selected {
                font-weight: bold !important;
            }
        }

        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        }
    }

    &.dark-header {
        .bm-burger-bars {
            background: #2a324b !important;
        }

        .navbar-collapse {
            background: transparent;
        }

        .nav-link,
        a {
            color: #2a324b;
            text-decoration: none;

            &.active {
                color: #2a324b;
            }
        }

        a.bm-item {
            color: white;
        }

        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        }
    }

    @media (max-width: 768px) {
        .navbar-expand-md .navbar-collapse {
            display: none !important;
        }
    }
}

.menu-setings-wrap a {
    color: white !important;
    cursor: pointer;
}

.navbar-toggler {
    position: absolute;
    top: 15px;
    right: 5px;
    border: none;
}



.form-label {
    cursor: pointer;
    color: #4f5d75;
    font-weight: 600;
    font-size: 15px;
    margin: 6px 0;
}

.form-check-input {
    cursor: pointer;

    &[type="radio"] {
        background-color: #ffffff;
        border-color: #d7d7d7;

        &:checked {
            background-color: #ffffff;
            border-color: #c7ccdb;
            // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%231E40A8'/%3e%3c/svg%3e");
        }
    }

    &:checked {
        background-color: #2a324b !important;
        border-color: #2a324b !important;
    }
}

.form-check-label {
    font-size: 13px;
    cursor: pointer;
}

.card {
    border-radius: 8px;
    box-shadow: 0px 0px 12px -3px #58585826 !important;
    border: none !important;
}

.btn-danger {
    color: white !important;
}

.form-control:read-only:not(:disabled):not(.read-only-gray) {
    background-color: white !important;
    opacity: 1;
}

.form-control.read-only-gray:read-only {
    background-color: #e8e8e8 !important;
}

.form-control:disabled {
    background-color: #e8e8e8 !important;
}

.input-group-white {
    background-color: white !important;
}

.input-group-gray {
    background-color: #e8e8e8 !important;
}

.imageSliderModal {
    .modal-content {
        background-color: transparent;
        border: none;
    }
}

.carousel-control-next:hover {
    cursor: default;
}

.carousel-control-prev:hover {
    cursor: default;
}

.carousel-control-next-icon {
    position: absolute;
    left: 200px;
}

.carousel-control-prev-icon {
    position: absolute;
    right: 200px;
}

@media (max-width: 809px) {
    .carousel-control-next-icon {
        visibility: hidden;
    }

    .carousel-control-prev-icon {
        visibility: hidden;
    }
}

/// </-- Bootstrap Overrides --/>

@media (max-width: 768px) {

    .bm-burger-button {
        left: -30px !important;
        top: 20px !important;
    }

    .form-check-label {
        font-size: 12px !important;
    }

    ///// <-- Header and navbar mixed with bootstrap --> ////
    .navbar-collapse {
        background: #2a324b !important;
        margin-right: 20px;
        padding: 0 11px;
        border: 1px solid #ffffff4f;
        border-radius: 4px;
        z-index: 1000;
        padding: 10px 17px;
    }

    ///// <--/ Header and navbar mixed with bootstrap --> ////
}



//// <--- Dashboard Sidebar Overrides --> ////
$sidebar-bg-color: #1d1d1d !default;
$sidebar-color: #adadad !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 0px !default;
$highlight-color: #d8d8d8 !default;
$submenu-bg-color: #2b2b2b !default;
$submenu-bg-color-collapsed: #2b2b2b !default;
$icon-bg-color: #2b2b2b !default;
$icon-size: 35px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;

.pro-sidebar {
    position: fixed !important;
    padding: 0 !important;
    margin-top: -30px;
    z-index: 100000 !important;
}

.pro-sidebar-inner {
    background: white !important;
}

.pro-icon-wrapper {
    display: none !important;
}

.pro-inner-item {
    color: #111111 !important;
    padding: 5px 35px 5px 20px !important;

    a {
        color: #111111 !important;
    }
}

//// <---/ Dashboard Sidebar Overrides --> ////

.rmsc .dropdown-container {
    padding: 3px 0;
}

.phone-input {
    width: 100% !important;
    height: 44.4px !important;
    border-radius: 6px !important;
    padding: 10px 48px !important;
    border: 1px solid #c7ccdb !important;
    line-height: 15px !important;
    color: #212529 !important;
    font: 400 13.3333px "overpass" !important;
}

.btn-success {
    // padding: 10px !important;
    color: white !important;
    // font-size: 14px !important;
}

.badge-success {
    background: #1da61b;
}

.navbar-dark .navbar-nav .nav-link {
    color: white !important;

    &:hover {
        color: #6282e3 !important;
        transition: all 0.14s;
    }
}

.dark-header .navbar-nav .nav-link {
    color: black !important;

}

.form-check {
    label {
        margin-top: 0;
        margin-bottom: 0;
    }
}


/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 21px;
    height: 20px;
    left: -37px;
    top: 25px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #ffffff;
    height: 2px !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #858585;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
}

/* General sidebar styles */
.bm-menu {
    background: #1e232e;
    padding: 15px;
    font-size: 16px;
    line-height: 35px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
    border: none !important;
    outline: none !important;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    left: 0;
    top: 0;
}

button[disabled] a {
    color: #666666;
}

.reporting-container {
    max-width: 1630px !important;

    .container {
        max-width: 1630px !important;

    }
}

.time-selector-wrap {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
    border: 1px solid #f9f9f9 !important;

    .react-datepicker__time-container {
        padding-top: 10px;
        width: 100px;
    }

    .react-datepicker__time-box {
        width: 100px !important;
    }

    .react-datepicker__triangle::after {
        border-bottom-color: #ffffff !important;

    }

    .react-datepicker__triangle::before {
        border-bottom-color: #e0e0e0 !important;

    }

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
        &::before {

            border-bottom-color: #e3e3e3 !important;
        }

        &::after {
            border-bottom-color: #ffffff !important;
        }
    }

    .react-datepicker__header--time {
        display: none !important;
    }

    .react-datepicker__header {
        background: white !important;
    }

    .react-datepicker__triangle {
        left: -39px !important;
    }
}

.form-check {
    cursor: pointer;
}

.spinner-border {
    width: 1rem !important;
    height: 1rem !important;
}

.react-datepicker-popper {
    z-index: 1000 !important;
}

fieldset:disabled {
    .date-picker-wrap {
        svg {
            display: none !important;
        }
    }

    button {
        cursor: default !important;
        // display: none;
    }

    .btn-table-cell {
        background: #b2b2b2 !important;
    }
}


.multi-select-calendar {
    .rdtPicker {
        border: 1px solid #0223411c;
        border-radius: 7px;
    }

    .dow {
        font-size: 18px;
        padding: 10px 0;
    }

}

.carousel-control-prev,
.carousel-control-next {
    width: 5% !important;
}

.tooltip-inner {
    max-width: 250px !important;
}

.tooltip.show {
    opacity: 1 !important;
}

.white-tooltip-wrap {
    .tooltip-arrow{
        &::before{
            border-right-color: white !important
        }
    }
    .tooltip-inner {
        background: white;
        box-shadow: 0px 0px 6px 2px #f0f0f0;
        color: black !important;
    }

}